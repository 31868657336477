<template>
	<div class="about">
		<!-- ___________________________________________________________________________ エラー画面 -->
		<v-container fluid  v-if="(result == 'keyError')">
			<v-row  align="center" justify="center">
				<div align="center" class="mt-15">
					<p>登録情報の取得に失敗しました。<br>
						URLをご確認ください。</p>
				</div>
			</v-row>
		</v-container>
		<!-- ___________________________________________________________________________ 入力画面 -->
		<v-container fluid  v-else-if="(result == 'none' || result == 'error' || result == 'makuakeError')">
			<v-row  align="center" justify="center">
				<v-col sm="12" md="10" lg="4" xl="4">
					<h2>情報の入力</h2>
					<v-form
							ref="form"
							v-model="valid"
							lazy-validation
					>
						<v-card
								class="mx-auto mb-5"
								outlined
						>
							<v-card-title>MAKUAKE（マクアケ）コード</v-card-title>
							<v-card-text>
								<v-text-field
										v-model="makuake_code"
										label="コード"
										:rules="makuake_codeRules"
										required
								></v-text-field>
							</v-card-text>
						</v-card>

						<v-card
								class="mx-auto mb-5"
								outlined
						>
							<v-card-title>お客様情報</v-card-title>
							<v-card-text>
								<v-text-field
										v-model="name1"
										label="お名前"
										:rules="name1Rules"
										required
								></v-text-field>

								<v-text-field
										v-model="furigana1"
										label="ふりがな"
										:rules="furigana1Rules"
										required
								></v-text-field>

								<v-menu
										ref="birthdayMenu"
										v-model="birthdayMenu"
										:close-on-content-click="false"
										transition="scale-transition"
										offset-y
										min-width="290px"
								>

									<template v-slot:activator="{ on }">
										<v-text-field
												v-model="birthday"
												:rules="birthdayRules"
												label="生年月日"
												prepend-icon="fas fa-birthday-cake"
												readonly
												v-on="on"

										></v-text-field>
									</template>
									<v-date-picker
											ref="picker"
											v-model="birthday"
											:max="new Date().toISOString().substr(0, 10)"
											min="1950-01-01"
											locale="ja"
											@change="save"
									></v-date-picker>
								</v-menu>

								<v-select
										v-model="sex"
										:items="sexs"
										:rules="sexRules"
										label="性別"
										required
								></v-select>

								<v-text-field
										v-model="zip"
										:rules="zipRules"
										label="郵便番号"
										required
										@blur="fetchAddress"
										placeholder="1010021"
								></v-text-field>

								<v-select
										v-model="city"
										:items="citys"
										:rules="cityRules"
										label="都道府県名"
										required
								></v-select>

								<v-text-field
										v-model="address1"
										:rules="address1Rules"
										label="市区町村"
										required
								></v-text-field>

								<v-text-field
										v-model="address2"
										:rules="address2Rules"
										label="以下住所"
										required
								></v-text-field>

								<v-text-field
										v-model="tel"
										:rules="telRules"
										label="電話番号"
										placeholder="090-0000-0000"
										required
								></v-text-field>

								<v-text-field
										v-model="login_pass"
										:rules="login_passRules"
										:counter="24"
										label="パスワード"
										required
										:append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
										:type="passwordShow ? 'text' : 'password'"
										@click:append="passwordShow = !passwordShow"
								></v-text-field>

							</v-card-text>
						</v-card>

						<v-card
								class="mx-auto mb-5"
								outlined
						>
							<v-card-title>食べ物に関して</v-card-title>
							<v-card-text>
								<v-textarea
										outlined
										v-model="allergy"
										label="アレルギー"
										placeholder="えび、かに、小麦、そば、卵、乳、落花生"
										value=""
								></v-textarea>

								<v-textarea
										outlined
										v-model="favorite_food"
										label="好きな食べ物"
										placeholder="まぐろ"
										value=""
								></v-textarea>

								<v-textarea
										outlined
										v-model="disliked_food"
										label="嫌いな食べ物"
										placeholder="セロリ、パクチー"
										value=""
								></v-textarea>

								<v-textarea
										outlined
										v-model="alcohol_note"
										label="よく飲むお酒"
										placeholder="日本酒,ワイン"
										value=""
								></v-textarea>
							</v-card-text>
						</v-card>

						<v-card
								class="mx-auto"
								outlined
						>
							<v-card-title>お店に関して</v-card-title>
							<v-card-text>
								<v-select
										v-model="favorite_genre"
										:items="favorite_genres"
										attach
										chips
										label="好きな飲食店"
										multiple
								></v-select>
								<v-divider class="mx-4 mb-5"></v-divider>
								<v-textarea
										outlined
										v-model="favorite_shop"
										label="よく行くお店"
										placeholder="渋谷のxxxx"
										value=""
								></v-textarea>

								<v-select
										v-model="purpose"
										:items="purposes"
										attach
										chips
										label="利用目的"
										multiple
								></v-select>
								<v-divider class="mx-4 mb-5"></v-divider>
								<v-select
										v-model="average_amount"
										:items="average_amounts"
										item-text="label"
										item-value="value"
										label="外食時の平均利用金額"
										return-object
								></v-select>

							</v-card-text>
						</v-card>


						<div align="center" class="ma-5">
							<v-btn
									color="#e60044"
									dark
									depressed
									tile
									x-large
									@click="goConfirm"
							>
								確認する
							</v-btn>
						</div>

					</v-form>

				</v-col>
			</v-row>

		</v-container>

		<!-- ___________________________________________________________________________ 確認画面 -->
		<v-container class="" fluid  v-else-if="result=='confirm'">
			<v-row  align="center" justify="center">
				<v-col sm="12" md="10" lg="4" xl="4">
					<h2>確認画面</h2>


					<v-card
							class="mx-auto mb-5"
							outlined
					>
						<v-card-title>MAKUAKE（マクアケ）コード</v-card-title>
						<v-card-text>
							<v-list-item two-line>
								<v-list-item-content>
									<v-list-item-title>コード</v-list-item-title>
									<v-list-item-subtitle>{{makuake_code}}</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</v-card-text>
					</v-card>

					<v-card
							class="mx-auto mb-5"
							outlined
					>
						<v-card-title>お客様情報</v-card-title>
						<v-card-text>

							<v-list-item two-line>
								<v-list-item-content>
									<v-list-item-title>お名前</v-list-item-title>
									<v-list-item-subtitle>{{name1}}</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>

							<v-list-item two-line>
								<v-list-item-content>
									<v-list-item-title>ふりがな</v-list-item-title>
									<v-list-item-subtitle>{{furigana1}}</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>

							<v-list-item two-line>
								<v-list-item-content>
									<v-list-item-title>生年月日</v-list-item-title>
									<v-list-item-subtitle>{{birthday}}</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>

							<v-list-item two-line>
								<v-list-item-content>
									<v-list-item-title>性別</v-list-item-title>
									<v-list-item-subtitle>{{sex}}</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>

							<v-list-item two-line>
								<v-list-item-content>
									<v-list-item-title>郵便番号</v-list-item-title>
									<v-list-item-subtitle>{{zip}}</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>

							<v-list-item two-line>
								<v-list-item-content>
									<v-list-item-title>都道府県名</v-list-item-title>
									<v-list-item-subtitle>{{city}}</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>

							<v-list-item two-line>
								<v-list-item-content>
									<v-list-item-title>市区町村</v-list-item-title>
									<v-list-item-subtitle>{{address1}}</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>

							<v-list-item two-line>
								<v-list-item-content>
									<v-list-item-title>以下住所</v-list-item-title>
									<v-list-item-subtitle>{{address2}}</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>

							<v-list-item two-line>
								<v-list-item-content>
									<v-list-item-title>電話番号</v-list-item-title>
									<v-list-item-subtitle>{{tel}}</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>

						</v-card-text>
					</v-card>

					<v-card
							class="mx-auto mb-5"
							outlined
					>
						<v-card-title>食べ物に関して</v-card-title>
						<v-card-text>

							<v-list-item two-line>
								<v-list-item-content>
									<v-list-item-title>アレルギー</v-list-item-title>
									<v-list-item-subtitle>{{allergy}}</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>

							<v-list-item two-line>
								<v-list-item-content>
									<v-list-item-title>好きな食べ物</v-list-item-title>
									<v-list-item-subtitle>{{favorite_food}}</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>

							<v-list-item two-line>
								<v-list-item-content>
									<v-list-item-title>嫌いな食べ物</v-list-item-title>
									<v-list-item-subtitle>{{disliked_food}}</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>

							<v-list-item two-line>
								<v-list-item-content>
									<v-list-item-title>よく飲むお酒</v-list-item-title>
									<v-list-item-subtitle>{{alcohol_note}}</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>

						</v-card-text>
					</v-card>

					<v-card
							class="mx-auto mb-5"
							outlined
					>
						<v-card-title>お店に関して</v-card-title>
						<v-card-text>

							<v-list-item two-line>
								<v-list-item-content>
									<v-list-item-title>好きな飲食店</v-list-item-title>
									<v-list-item-subtitle>{{favorite_genre}}</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>

							<v-list-item two-line>
								<v-list-item-content>
									<v-list-item-title>よく行くお店</v-list-item-title>
									<v-list-item-subtitle>{{favorite_shop}}</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>

							<v-list-item two-line>
								<v-list-item-content>
									<v-list-item-title>利用目的</v-list-item-title>
									<v-list-item-subtitle>{{purpose}}</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>

							<v-list-item two-line>
								<v-list-item-content>
									<v-list-item-title>外食時の平均利用金額</v-list-item-title>
									<v-list-item-subtitle v-if="average_amount.label">{{average_amount.label}}</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>

						</v-card-text>
					</v-card>
					<div align="center" class="ma-5">
						<v-btn
								color="#e60044"
								class="ma-2"
								dark
								depressed
								tile
								x-large
								@click="goInput"
						>
							前に戻る
						</v-btn>
						<v-btn
								color="#e60044"
								class="ma-2"
								dark
								depressed
								tile
								x-large
								@click="goRegister"
						>
							登録
						</v-btn>
					</div>
				</v-col>
			</v-row>
		</v-container>

		<!-- ___________________________________________________________________________ 完了画面 -->
		<v-container class="" fluid  v-else-if="result=='success'">
			<div class="about">
				<v-container class="pa-0" fluid>
					<v-row  align="center" justify="center">
						<v-col sm="12" md="10" lg="8" xl="8" align="center" style="margin-top: 100px">


							<p>ご登録いただき、ありがとうございます。<br>
								会員登録完了のご案内メールをお送り致しました。</p>


						</v-col>
					</v-row>
				</v-container>
			</div>
		</v-container>

		<v-snackbar
				v-model="snackbar"
				:timeout="snackbarTimeout"
				color="error"
				top
		>
			{{ snackbarText }}
			<v-btn
					text
					@click="snackbar = false"
			>
				Close
			</v-btn>
		</v-snackbar>
	</div>
</template>


<script>
	import mailRegist from '@/api/mailRegist.js'
	import makuake from '@/api/makuake.js'
	import axios from 'axios';
	import axiosJsonpAdapter from 'axios-jsonp'
	let url 		= process.env.VUE_APP_API_ORIGIN+"/api_register/api";
	const ZIPCODE_API_URL = 'https://zipcloud.ibsnet.co.jp/api/search'


	export default {
		props: {
			para: String,
		},
		data: () => ({
			axios,
			url,
			mailRegist: null,
			makuake: null,
			result: 'none',
			valid: false,

			/** ________________________________________ snackbar設定 **/
			snackbar: false,
			snackbarText: '',			//	表示テキスト
			snackbarTimeout: 3000,		//	タイムアウト秒数

			/** ________________________________________ 項目設定 **/
			// お客様情報
			name1: null,		// お名前
			name1Rules: [
				v => !!v || 'お名前を入力してください',
			],
			furigana1: null,	// ふりがな
			furigana1Rules: [
				v => !!v || 'ふりがなを入力してください',
				v => /^[あ-ん゛゜ぁ-ぉゃ-ょー「」、]+/.test(v) || 'ふりがなを「ひらがな」でご入力ください。',
			],
			birthday: null,		// 生年月日
			birthdayMenu: false,
			birthdayRules: [
				v => !!v || '生年月日を入力してください',
			],
			sex: null,			// 性別
			sexRules: [
				v => !!v || '性別を選択してください',
			],
			sexs:['男性','女性'],
			zip: null,			// 郵便番号
			zipRules: [
				v => /^\d{1,7}$/.test(v) || '郵便番号を「半角数字」7桁でご入力ください。',
				v => !!v || '郵便番号を入力してください',
			],
			city: null,			// 都道府県名
			citys: ['北海道','青森県','岩手県','宮城県','秋田県','山形県','福島県','茨城県','栃木県','群馬県','埼玉県','千葉県','東京都','神奈川県','新潟県','富山県','石川県','福井県','山梨県','長野県','岐阜県','静岡県','愛知県','三重県','滋賀県','京都府','大阪府','兵庫県','奈良県','和歌山県','鳥取県','島根県','岡山県','広島県','山口県','徳島県','香川県','愛媛県','高知県','福岡県','佐賀県','長崎県','熊本県','大分県','宮崎県','鹿児島県','沖縄県'],
			cityRules: [
				v => !!v || '都道府県名を選択してください',
			],
			address1: null,		// 市区町村
			address1Rules: [
				v => !!v || '市区町村を入力してください',
			],
			address2: null,		// 以下住所
			address2Rules: [
				v => !!v || '以下住所を入力してください',
			],
			tel: null,			// 電話番号
			telRules: [
				v => !!v || '電話番号を入力してください',
				v => /^\d{1,4}-\d{1,4}-\d{1,4}$/.test(v) || '電話番号を「半角数字」10～11桁でご入力ください。',
				v => v != undefined && v.length <= 14 || '電話番号を「半角数字」10～11桁でご入力ください。',
			],
			login_pass: null,	// パスワード
			login_passRules: [
				v => !!v || 'パスワードを入力してください',
				v => v != undefined && v.length >= 6 || 'パスワードを6文字から24文字でご入力ください。',
				v => v != undefined && v.length <= 24 || 'パスワードを6文字から24文字でご入力ください。',
			],
			passwordShow:false,


			// 食べ物に関して
			allergy: null,		// アレルギー
			favorite_food: null,// 好きな食べ物
			disliked_food: null,// 嫌いな食べ物
			alcohol_note: null,	// よく飲むお酒

			//お店に関しt
			favorite_genre:null,	// 好きな飲食店
			favorite_genres:['焼き肉','寿司','居酒屋','イタリアン','ラーメン','カフェ'],
			favorite_shop:null,	// よく行くお店
			purpose: null,		// 利用目的
			purposes:['デート','接待','会食','記念日','友人と'],
			average_amount:[],// 外食時の平均利用金額
			average_amounts:[
				{ label: '5,000円～', value: '5000'},
				{ label: '10,000円～', value: '10000'},
				{ label: '15,000円～', value: '15000'},
				{ label: '20,000円～', value: '20000'},
			],

			makuake_code:null,	// マクアケコード
			makuake_codeRules: [
				v => !!v || 'MAKUAKEコードを入力してください',
			]

		}),
		watch: {
			birthdayMenu (val) {
				val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
			},
		},
		methods: {
			/** __________________________________________________ 認証キーの称号 **/

			// メールアドレス認証
			async setInfo(info) {
				this.mailRegist = info.data;

				// 認証エラー
				console.log(this.mailRegist.result);
				if(this.mailRegist.result != 'success') {
					this.result = 'keyError'
				}
			},
			// マクアケコード認証
			async setInfo_makuake(info) {
				this.makuake = info.data;

				// 認証エラー
				console.log(this.makuake.result);
				if(this.makuake.result != 'success') {
					this.result = 'makuakeError'
					this.snackbarText = 'MAKUAKE（マクアケ）コードを確認できませんでした。'
					this.snackbar = true
				}
			},

			/** __________________________________________________値の一時保存 **/
			save (value) {
				this.$refs.birthdayMenu.save(value)
			},

			/** __________________________________________________確認画面 **/
			goConfirm () {
				if (this.$refs.form.validate()) {

					// makuakeコード認証
					makuake.checkCode(this.setInfo_makuake, this.makuake_code)

					this.$nextTick(()=> {

						// 確認画面を表示
						this.result = "confirm"
						window.scrollTo({
							top: 0,
						});

					})


				//バリデーションエラー
				}else {
					window.scrollTo({
						top: 0,
					});
				}
			},
			goInput () {
				this.result = 'none'
				window.scrollTo({
					top: 0,
				});
			},
			/** __________________________________________________ 登録 **/
			goRegister () {
				let params = new URLSearchParams();
				params.append('mode', "userRegister");
				params.append('mailRegistNum', this.mailRegist.no);
				params.append('usermail', this.mailRegist.mail);				//メールアドレス
				params.append('name1', this.name1);							// お名前
				params.append('furigana1', this.furigana1);					// ふりがな
				params.append('birthday', this.birthday);						// 生年月日
				params.append('sex', this.sex);								// 性別
				params.append('zip', this.zip);								// 郵便番号
				params.append('city', this.city);								// 都道府県名
				params.append('address1', this.address1);						// 市区町村
				params.append('address2', this.address2);						// 以下住所
				params.append('tel', this.tel);								// 電話番号
				params.append('login_pass', this.login_pass);					// パスワード
				params.append('allergy', this.allergy);						// アレルギー
				params.append('favorite_food', this.favorite_food);			// 好きな食べ物
				params.append('disliked_food', this.disliked_food);			// 嫌いな食べ物
				params.append('alcohol_note', this.alcohol_note);				// よく飲むお酒
				params.append('favorite_genre', this.favorite_genre);			// 好きな飲食店
				params.append('favorite_shop', this.favorite_shop);			// よく行くお店
				params.append('purpose', this.purpose);						// 利用目的
				params.append('average_amount', this.average_amount.value);	// 外食時の平均利用金額
				params.append('makuake_code', this.makuake_code);				// マクアケコード

				axios.post(url, params)
					.then(res => {

						//console.log(res.data)
						console.log(res.data.result)
						this.result = res.data.result

						if(this.result == 'error') {
							switch(res.data.errorCode) {
								case'001':
									this.snackbarText = 'MAKUAKE（マクアケ）コードを確認できませんでした。'
									break;
							}
							this.snackbar = true
						}else {
							this.snackbarText = "登録完了"
						}


					}).catch(error => {
					this.snackbarText = error
					this.snackbar = true
				})

			},
			/** __________________________________________________ 郵便番号処理 **/
			async fetchAddress() {
				// 郵便番号のバリデーションチェック
				const reg = /^\d{7}$/
				if (!reg.test(this.zip)) return

				console.log("hoge")

				// 住所apiを叩く
				const res = await axios.get(ZIPCODE_API_URL, {
					adapter: axiosJsonpAdapter,
					params: {
						zipcode: this.zip
					}
				})

				console.log(res.data.results[0]);

				// 存在しない郵便番号でapiを叩くと200以外のステータスが返ってくる
				if (res.status !== 200) return

				// 返却されたデータを挿入する
				this.city 		= res.data.results[0].address1
				this.address1 	= res.data.results[0].address2 + res.data.results[0].address3
			}

		},
		created() {
			// メールアドレス認証
			console.log(this.para)
			mailRegist.checkKey(this.setInfo, this.para);
		}



	}
</script>

<style scoped>
	@import "~@/styles/form.css";
</style>
