import axios from "axios";

//接続URL
let url = process.env.VUE_APP_API_ORIGIN+"/api_makuake/";

export default {
	checkCode: async function(callback,key) {
        await axios.get(url + "?code="+key).then(response => callback(response));
    },
};
